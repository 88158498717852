
.App {
  width: 100%;
  display: flex;
  background-color: #11121F;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #666677;
  opacity: 1; /* Firefox */
}

.mapview {
  width: 100%;
  height: 100vh;
  display: flex;
}

#mapLoading {
  margin: auto;
  width: 30px;
  height: 30px;
}

.placeMarkerStyle, .imageMarkerStyle {
  cursor: pointer;
  transform: translateY(7px);
  animation: 1.5s ease-in fadein;
}

.placeMarkerStyleTop {
  background-color: #271d58;
  border-radius: 2px;
  padding: 4px 6px;
  box-shadow: 0px 0px 5px -1px black;
  color: white;
  position: relative;
}

.imageMarkerStyleTop {
  background-color: #271d58;
  padding: 5px 5px 0px 5px;
  position: relative;
  box-shadow: 0px 0px 5px -1px black;
  border-radius: 2px;
}

.imageMarkerStyleTop img {
  border-radius: 2px;
  width: 75px;
  height: 75px;
  object-fit: cover;
}

.placeMarkerStyleBottom,  .imageMarkerStyleBottom{
  border: 7px solid transparent;
  border-top-color: #271d58;
  width: 0;
  margin: auto;
}


.placeMarkerStyleMid {
  background-color: #6521ff;
  border-radius: 0 2px 2px 0;
  box-shadow: 0px 0px 5px -1px black;
  color: white;
  position: absolute;
  white-space: nowrap;
  top: 0;
  left: calc(100%);
  overflow: hidden;
  width: fit-content;
  max-width: 0;
  border: none;
  display: block;
  color: white;
  transition: max-width 0.5s;
}

.name {
  padding: 4px 6px;
  display: block;
}

.placeMarkerStyle:hover .placeMarkerStyleTop {
  border-radius: 2px 0 0 2px;
} 

.placeMarkerStyle:hover .placeMarkerStyleMid {
  max-width: 250px;
}

.mapboxgl-ctrl {
  display: none !important;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.imageMarkerModal {
  display: block;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  border-radius: 2px;
  top: 5px;
  left: 5px;
  opacity: 0;
  transition: opacity 0.1s;
}

.imageMarkerModal b {
  background-color: #271d58;
  color: white;
  padding: 4px 6px;
  display: block;
  width: fit-content;
  margin: auto;
  margin-top: 25.5px;
  border-radius: 2px;
}

.resetGlobe {
  position: absolute;
  padding: 10px 15px;
  background-color: #6521ff;
  bottom: 50px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  color: white;
  font-weight: bold;
  cursor: pointer;
  border-radius: 2px;
  box-shadow: 0px 0px 5px -1px black;
  animation: 0.2s ease-in zoom;
}

@media only screen and (min-width: 600px) {
  .imageMarkerStyle:hover .imageMarkerModal {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .mapview {
    height: calc(100svh + 1px);
  }

  .placeMarkerStyle:hover .placeMarkerStyleTop {
    border-radius: 2px;
  } 
  
  .placeMarkerStyle:hover .placeMarkerStyleMid {
    max-width: 0;
  }
}

@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  img {
    clip-path: inset(0.6px)
  }
}

@keyframes zoom {
  from { opacity: 0; }
  to { opacity: 1; }
}