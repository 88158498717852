.navigation {
  position: absolute;
  margin-top: 60px;
  display: flex;
  justify-content: flex-start;
  padding: 0 60px;
  z-index: 100;
}

.navigation a {
  font-size: 22px;
  color: white;
  font-weight: bold;
  margin-right: 30px;
  margin-left: 0px;
  padding-left: 5px;
  padding-right: 5px;
  text-decoration: none;
  text-shadow: 0px 0px 4px black;
}

.navigation a:hover {
  cursor: pointer;
}

.navigation .active {
  padding-bottom: 1px;
  border-bottom: 3px solid #6521ff;
}

@media only screen and (max-width: 600px) {
  .navigation {
    width: 100%;
    margin-top: 30px;
    padding: 0;
    justify-content: center;
  }

  .navigation a {
    margin-right: 15px;
    margin-left: 15px;
  }
}
