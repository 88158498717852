.gallery {
  width: 100%;
}

.galleryContainer {
  padding-top: 120px;
  padding: 120px 60px 60px 60px;
}

.show {
  display: inline-block !important;
}

.lazy-image {
  visibility: hidden;
  opacity: 0;
  transition: opacity 1s ;
}

.loader {
  z-index: 10;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 2.5rem;
  transform: translateX(-50%) translateY(-50%);
}

.loaded {
  visibility: visible;
  opacity: 1;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding-top: 20px;
}

.image-gallery > .gallery-item {
  cursor: pointer;
  position: relative;
  display: none;
  flex-grow: 1;
  flex: 1 1 auto;
}

.image-gallery .gallery-item img {
  object-fit: cover;
  vertical-align: middle;
  border-radius: 5px;
  height: 200px;
  width: 100%;
}

.image-gallery > .gallery-item-mobile {
  height: 200px;
  cursor: pointer;
  position: relative;
  display: none;
  flex-grow: 1;
  object-fit: cover;
  border-radius: 5px;
}


.select__control {
  background-color: transparent !important;
  color: white;
  border: 1px solid darkgrey;
  box-shadow: 0px 0px 0px white !important;
}

.select__control:hover {
  cursor: pointer;
}

.select__input-container, .select__placeholder {
  color: white !important;
}

.select__control--is-focused {
  border: 1px solid darkgrey !important;
}

.select__multi-value {
  background-color: #6521ff !important;
}

.select__multi-value__label {
  color: white !important;
}

.select__multi-value__remove:hover {
  background-color: transparent !important;
  color: lightgray !important;
}

.select__menu {
  background-color: #11121F !important;
  border: 1px solid darkgrey;
}

.select__option {
  color: white !important;
}

.select__option--is-focused {
  background-color: #271d58  !important;
}

@media only screen and (max-width: 600px) {
  .galleryContainer {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 90px;
  }

  .image-gallery {
    gap: 10px;
  }
}

.imageModal {
  display: block;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  border-radius: 2px;
  top: 0px;
  left: 0px;
  opacity: 0;
  transition: opacity 0.1s;
}

.imageModal b {
  background-color: #271d58;
  color: white;
  padding: 6px 10px;
  display: block;
  width: fit-content;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 2px;
  font-size: 12px;
  text-align: center;
}

.imageModal:hover {
  opacity: 1;
}

@media only screen and (max-width: 600px) {
  .imageModal:hover {
    opacity: 0;
  }
  .loader {
    width: 2rem;
  }
}

@media only screen and (min-width: 600px) {
  .image-gallery::after {
    content: "";
    flex-grow: 999;
  }
}


