.fullScreenModal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 101;
  background-color: rgba(17, 18, 31, 0.92);
}
.fullScreenModal .imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 100px);
}

.spinner {
  color: white;
  font-weight: bold;
}

.fullScreenModal .info {
  color: white;
  padding: 20px;
  height: 40px;
}

.fullScreenModal .close {
  position: absolute;
  color: white;
  right: 20px;
  top: 29px;
  font-weight: bold;
  cursor: pointer;
}

.fullScreenModal img {
  width: auto;
  height: auto;
  max-height: calc(100% - 40px);
  max-width: calc(100% - 40px);
  object-fit: contain;
  margin: auto;
  animation: 0.2s ease-in fadein;
  border-radius: 4px;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .fullScreenModal {
    height: calc(100svh + 3px);
  }
}